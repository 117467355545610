var arr = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'التحكم في الطلبات',
    route: 'orders',
    icon: 'CalendarIcon',
    per: "orders"
  },
  {
    title: 'في انتظار الالغاء',
    route: 'orders-canceled',
    icon: 'XSquareIcon',
    per: "orders"
  },
  {
    title: 'تصفية طلبات المحصل',
    route: 'orders-cash',
    icon: 'DollarSignIcon',
    per: "orders-cash"
  },
  {
    title: 'تصفية طلبات الوكيل',
    route: 'orders-reseller',
    icon: 'DollarSignIcon',
    per: "orders-reseller"
  },
  {
    title: 'سجل التصفيات',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'طلبات المحصل',
        route: 'archive|cash'
      },
      {
        title: 'طلبات الوكيل',
        route: 'archive|reseller'
      },
    ],
  },
  {
    title: 'التحكم في الشركات',
    route: 'companies',
    icon: 'BriefcaseIcon',
    per: "companies"
  },
  {
    title: 'التحكم في الفنادق',
    route: 'hotels',
    icon: 'MapIcon',
    per: "hotels"
  },
  {
    title: 'التحكم في الوكلاء',
    route: 'resellers',
    icon: 'UsersIcon',
    per: "resellers"
  },
  {
    title: 'التحكم في المحصلين',
    route: 'cashs',
    icon: 'UsersIcon',
    per: "cashs"
  },
  {
    title: 'التحكم في المشرفين',
    route: 'admins',
    icon: 'ShieldIcon',
    per: "admins"
  },
  {
    title: 'الاعدادات العامة',
    route: 'settings',
    icon: 'SettingsIcon',
    per: "settings"
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if(user.pers){
    if(user.pers.includes("*") || user.pers.includes(e.per) || !e.per){
      arr2.push(e)
    }
  }else{
    arr2.push(e)
  }

})

export default arr2
